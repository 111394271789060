.frontside{
    background-image: url("/storage/app/media/home/bg-front2.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    width: auto;
    height: 100vh;
    background-position: center;
    display: flex;
    justify-content: center;
    @include mq($until: sm) {
        background-image: url("/storage/app/media/home/coal-bg.jpg");
        background-color: darkgrey;
        background-blend-mode: luminosity;
    }
    &__choser{
        position: relative;
        width: 100%;
    }
    &__belt {
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 2;
        position: relative;
        svg{
            height: 100vh;
            width: auto;
            @include mq($until: sm) {

            }
        }

    }
    &__wrapper{
        position: absolute;
        z-index: 10;
        top: calc(100vh - 33.5vh);
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        width: 40vw;
        :last-child{
            text-align: right;
            @include mq($until: sm) {
                text-align: center;
                margin-top: 0;
            }
        }
        h2 {
            color:#fff;
            @include mq($until: sm) {
                text-align: center;
                font-size: rem(21px);
            }

        }
        span{
            color:#fff;
            font-size: 64px;
            @include mq($until: sm) {
                font-size:34px ;
            }
            @include mq($until: xs) {
                font-size:30px ;
            }
        }
        width: 100%;

        @include mq($until: sm) {
            flex-direction: column;
            top: calc(100vh - 40.5vh);
            justify-content: flex-start;
        }
        @include mq($until: xs) {
            top: calc(100vh - 46.5vh);
        }
    }
    &__detalo{
        display: flex;
        align-items: center; 
        margin-right: auto;
        width: 39vw; 
        justify-content: space-between;
        svg{
            height: 100px;
            @include mq($until: sm) {
                display: none;
            }
        } 
        transition: all .4s ease-in-out;
        &:hover  {
            h2, span, svg{
                color: $color-main;
                fill:$color-main ;
            }  
        }
        @include mq($until: sm) {
            flex-direction: row-reverse;
            margin-left: auto;
            width: auto;
        }

    }
    &__hurt{
        display: flex;
        align-items: center;  
        width: 39vw; 
        justify-content: space-between; 
        svg{
            height: 100px;
            @include mq($until: sm) {
                display: none;
            }
        } 
        transition: all .4s ease-in-out;
        &:hover  {
            h2, span, svg{
                color: $color-main;
                fill:$color-main ;
            }  
        }
        @include mq($until: sm) {
            flex-direction: row-reverse;
            width: auto;
        }
    }
    &__mask{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100vh;
        z-index: 8;
  
      }
      &__left{    
          width: 50%;
          height: 100vh;   
      }
      &__right{    
          width: 50%;
          height: 100vh;  
      }
      &__action{
        &--l{
            background: linear-gradient(90deg, rgb(184, 124, 19) 0%, rgba(0,0,0,0) 98%);
        }
        &--r{
            background: linear-gradient(90deg, rgba(0,0,0,0) 0%,rgb(184, 124, 19));
        }
        opacity: 0;
        transition: all .4s ease-in-out;

        &:hover{
            opacity: .4;
        }
        
      }

}

@include mq($until: sm) {
    p{
        font-size: 18px;
    }
}
.top-wrapper{
    &__pictureblock{
        // background-width: 100vw;
        background-image: url("/storage/app/media/home/top-foto.jpg");
        background-repeat: no-repeat;
        background-size:contain;
        height: 36vw;
        background-position:0 -8vw;
        background-attachment: fixed;
        margin-bottom: 3vw;
        @include mq($until: sm) {
            background-attachment:scroll ;
        }
        h1{
            padding-top:13vw;
            color: #fff;
            font-weight:800;
            @include mq($until: md) {
                font-size: rem(34px);
            }
            @include mq($until: sm) {
                padding-top:40vw;
                color: $color-regular;
                opacity: .8;
                z-index: -5;
                font-size:4.5vw;
            }
            @include mq($until: xs) {
                padding-top:44vw;
                font-size:6.5vw;
            }
        }
        @include mq($until: sm) {
            height: 50vw;
            background-size:contain;
        }
        @include mq($until: xs) {
            margin-bottom: 13vw;
        }
    }

  
    &__info{
        display: flex;
        margin-bottom: 5vw;
        @include mq($until: sm) {
            flex-direction: column;
        }
        @include mq($until: xs) {
          padding-top:21px;
        }
    }
    &__text{
        display: flex;
        flex-direction: column;
        width: 45%;
        @include mq($until: sm) {
            width: auto;
            padding-top: 21px;
        }
    }
    &__logos{
        width: 45%;
        margin-left: 4vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img{
            max-height: 8vw;
            @include mq($until: sm) {
                max-height: 15vw;
            }
        }
        p{
           color:#6c757d ; 
           @include mq($until: sm) {
            font-size: 800;
           }
        }
        @include mq($until: sm) {
            width: auto;
            margin-left: 0;
            margin-top: 21px;
        }
    }
}

.paralsection {
    height: auto;
    background-image: url("/storage/app/media/home/coal-bg.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    background-attachment: fixed;
    position: relative;
    &__upper{
            width: 100vw;
            margin-bottom: 5vw;     
    }
    &__lower{
        width: 100%;
        margin-top: 5vw;
    }
    &__infobar{
        h2,a,p{
            color: #fff;
        }
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 5vw;
        @include mq($until: sm) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__prices svg{
        max-width: 100%;
        height: auto;
        .hexitem{
            transition: opacity 0.3 ease-in-out;
            &:hover > .cov {
                opacity: 0.8;
            }

            .cov{
                opacity: 0;
            }


        }
    }
    &__pin{
        display: flex;
        @include mq($until: sm) {
            flex-wrap: wrap;
            /* align-items: center; */
            justify-content: center;
        }
    }
    &__lokalizator{
        margin-left: 21px;
        display: inline-block;
        @include mq($until: xs) {
            margin-left: 0;
        }
    }
    &__hex1{
        svg *{
            font-family:'Montserrat', serif;
            font-weight: 900;
        }
        @include mq($until: md) {
            display: none;
        }
    }
    &__hex2{
        display: none;
        svg *{
            font-family:'Montserrat', serif;
            font-weight: 900;
            image{
                margin: 10px;
                height: 892px;
                width: 1270px;
            }
            #image0_96_8{
                margin: 10px;
            }
        }
        @include mq($until: md) {
            display: block;
        }
    }

    // &__hex{
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     position: relative;
    //     max-width: 21vw;
    //     z-index: 1;
        
    //     h3{
    //         position: absolute;
    //         z-index: 3;
    //         color: #fff;
    //         font-weight: bold;
    //         text-shadow: 4px 4px 26px rgba(66, 68, 90, 1);
    //         text-align: center;
    //         top: 5vw;
    //     }
    //     a{
    //         position: absolute;
    //         display: inline-block; 
    //         z-index: 3;
    //         bottom: 3vw;
    //         opacity: 0;
    //         transition: opacity 0.3 ease-in-out;
    //         // .paralsection__mask:hover{
    //         //     opacity: .8;
    //         // }
    //     }
    //     transition: all .3 ease-in-out;
    //     &:hover > a {
    //         opacity: 1;
    //     }
    //     &:hover > .paralsection__mask {
    //         opacity: 0.8;
    //     }
    //     &--1{
    //        left: 0;
    //     }
    //     &--2{
    //         left: 21%;
    //         top: 33%;
    //         position: absolute;
    //     }
    //     &--3{
    //         left: 38.3%;
    //         top: 47.5%;
    //         position: absolute;
    //     }
    //     &--4{
    //         position: absolute;
    //         left: 55.5%;
    //         top: 32.5%;
    //     }
    //     &--5{
    //         left: 72.6%;
    //         top: 47.5%;
    //         position: absolute;
    //     }
    // }

    // &__mask{
    //     position: absolute;
    //     z-index: 2;
        
    //     svg{
    //         width: 21vw;
    //         fill: $color-main;
    //     }
    //     opacity: 0;
    //     transition: opacity 0.3 ease-in-out; 
    // }
    &__icons{
        display: flex;
        width: 100%;
        padding-top: 5vw;
        padding-bottom: 5vw;
        justify-content: space-around;
        @include mq($until: sm) {
            flex-direction: column;
           align-items: center;
        }

    }
    &__ico{
        width: 24vw ;
        @include mq($until: sm) {
            width: 40vw;
            margin: 21px;
        }
        @include mq($until: xs) {
           
            margin: 21px;
        }
    }
}

.sellinfo{
    padding-top: 8vw;
    padding-bottom: 8vw;
    h3{
        color: $color-main;
    }
    &__wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mq($until: sm) {
           flex-direction: column;
        }
    }
    &__about{
        max-width: 48%;
        @include mq($until: sm) {
            max-width: unset;
            margin-bottom: 21px;
         }
    }
    &__contact{
        max-width: 48%;
        @include mq($until: sm) {
            max-width:100%;
            margin-right: auto;
         }
    }
    &__map{
        #gmap_canvas{
            width: 42vw;
            height: 40vw;
            @include mq($until: md) {
                width: 44vw;
                height: 60vw;
            }
            @include mq($until: sm) {
                width: 94vw;
                height: 60vh;
            }
        }

    }
    &__bullet{
        display: flex;
        align-items: center;
        padding-bottom: 13px;
        p,a{
            margin-left: 13px;
            font-size: rem(21px);
        }
        svg{
            height: 40px;
        }
    }
    &__contacts{
       margin-top: 5vw;
display: flex;
flex-wrap: wrap;
        align-items: center;
    }
    &__hours{
        padding-left: 5vw;
    }

}
