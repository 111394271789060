.paralsection{

.subfooter{
  padding-top: 3vw;
  padding-bottom: 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
    &__wrapper{
      margin-left: auto;
    }
    &__contacts{
      flex-wrap: wrap;
      a,p{
        color: #fff;
      }
      h3 {
        color: $color-main;
        padding-bottom: 13px;
      }
      width: 80%;
      width: auto;
        display: flex;
        justify-content: space-between;
    }
    &__addres{
        padding-left: 5vw;
    }
    &__data{
      padding-left: 5vw;
      justify-content: space-between;
      align-self: flex-end;
      flex-wrap: wrap;
      @include mq($until: xl) {
        display: flex;
        width: inherit;
        // justify-content: space-between;
        justify-content: flex-end;
        padding-top: 21px;
        width: 100%;
      }
      @include mq($until: md) {
        width: unset;
        padding-bottom: 34px;
      }
      @include mq($until: xs) {
        justify-content: flex-start;
      }
      p{
        margin-bottom: 13px;
      }
      
    }
    &__block1{
      @include mq($until: xs) {
        padding-right: 21px;
      }
    }
    &__block2{
      @include mq($until: xl) {
        padding-left: 8vw;
      }
      // @include mq($until: lg) {
      //   padding-left: 8vw;
      // }
      @include mq($until: md) {
        padding-left: 6.5vw;
      }
      @include mq($until: xs) {
        padding-left:unset;
      }
      
    }
    &__contact{
      padding-left: 5vw;
    }
    &__contactwrap{
      display: flex;
      @include mq($until: xl) {
        align-self: flex-end;
        width: 100%;
        justify-content: flex-end;
      }
      @include mq($until: md) {
        width: unset;
      }
      @include mq($until: xs) {
        flex-direction: column;
      }
    }
    &__logo{
      svg{
        width: 20vw;
      }
      @include mq($until: md) {
        padding-right: 8vw;
      }
      @include mq($until: sm) {
        padding-right: unset;
        padding-top: 34px;
        padding-bottom: 80px;
        svg{
          width: 60vw;
        }
      }
    }
    @include mq($until: sm) {
      flex-direction: column;
      flex-wrap: wrap;

    }
  }
}

.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}