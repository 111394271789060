.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 100px;
  position: absolute;
  width: 100%;
  z-index: 50;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: rgba(0, 0, 0, 0.583);
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    width: 180px;
    top: 21px;
    @include mq($until: sm) {
      max-width: 160px;
    }
    @include mq($until: xs) {
      max-width: 130px;
    }

  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    @include mq($until: md) {
      justify-content:center;
    }
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #fff;
    font-weight: 600;
    font-size: rem(21px);
    @include mq($until: md) {
      color: $color-regular;
      font-size: rem(40px);
      margin:3vw;
    }

    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }

}