.subsite {
    margin-bottom: 10px;
    // padding-top: 2vw;
    &__header {
      width: 100%;
      height: 34vw;
      display: flex;
      align-items: flex-end;
      margin-bottom: 40px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
      background-attachment: fixed;

    }
    // .paralsection__lower{
    //     transform: rotateY(180deg);
    // }
}